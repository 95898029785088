<template>
    <div class="raidio_container">
        <div class="raidio_img1_wrap">
            <img class="raidio_img1" src="/media/img/raidioevent/raidioimg1.png" alt="raidioimg1.png">
            <button class="raidio_application_btn" @click="goGoogleForm">참여하기</button>
        </div>
        <img class="raidio_img2" src="/media/img/raidioevent/raidioimg2.png" alt="유의사항">
    </div>
</template>
<script>
export default {
  data () {
    return {
      app: null,
      htmlele: null
    };
  },
  mounted () {
    this.app = document.querySelector('.app');
    this.htmlele = document.querySelector('html');
    this.app.classList.add('raidioevent');
    this.htmlele.classList.add('raidioevent');
  },
  methods: {
    goGoogleForm () {
      window.open(`https://forms.gle/H4RDZKj22ALV9ciEA`, '_blank');
    }
  },
  unmounted () {
    this.app.classList.remove('raidioevent');
    this.htmlele.classList.remove('raidioevent');
  }
};
</script>
<style src="@/assets/css/event/calendarfont.css"></style>
<style scoped src="@/assets/css/raidioevent/raidioevent.css"></style>
